<template>
  <div class="container">
    <div class="row justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center px-5 pt-5">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'home' })"
          class="btn btn-warning d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-caret-left-square fs-4"></i>
          <span class="m-2"> Home </span>
        </button>
      </div>
    </div>
    <!-- <button @click="refresh()">ACTUALISER</button> -->

    <div class="container login">
      <div class="d-flex justify-content-center">
        <div v-if="storekeepers == ''">
          <div class="icon-loader">
            <i class="bi bi-person-bounding-box icon-loader"></i>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div
          v-for="(item, index) in storekeepers"
          :key="index++"
          class="col-auto"
        >
          <button
            @click="
              $router.push({
                name: 'login-password',
                params: { reference: item.reference },
              })
            "
            class="btn btn-info"
          >
            <span class="fs-1">
              <i class="bi bi-person-bounding-box fs-1"></i>
            </span>
            <br />
            <span class="fw-bold fs-5">
              {{ item.fullName }}
            </span>
            <br />
            <span>
              {{ item.role }}

              {{ item.reference }}
            </span>
          </button>
        </div>
      </div>
      <!-- {{ storekeepers }} -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters("storekeeper", {
      storekeepers: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("storekeeper/getAll");
  },
  methods: {
    refresh() {
      this.$store.dispatch("storekeeper/getAll");
    },
  },
};
</script>
<style scoped>
.login {
  height: 30em;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  animation: load 1s linear infinite alternate;
}
@keyframes load {
  from {
    background-color: #dddd;
    color: #dddd;
  }
  to {
    background-color: #aaaa;
    color: #aaaa;
  }
}
@keyframes icon-load {
  from {
    color: #dddd;
  }
  to {
    color: #aaaa;
  }
}
.icon-loader {
  font-size: 3em;
  animation: icon-load 1s linear infinite alternate;
}
</style>
